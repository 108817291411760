jQuery(document).ready(function ($) {
	$(".hero__owl").owlCarousel({
		loop: true,
		items: 1,
		autoplay: true,
		autoplayTimeout: 5000,
	});
	$(".review__owl").owlCarousel({
		loop: true,
		items: 1,
		nav: true,
		navText: [
			`<svg width="12" height="32" viewBox="0 0 12 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_11_71)"><path d="M1.28631 15.2909C1.1616 15.1755 1.09925 15.0429 1.09925 14.893C1.09925 14.7431 1.1616 14.6104 1.28631 14.4951L10.0029 6.43373C10.1276 6.31841 10.271 6.26074 10.4331 6.26074C10.5952 6.26074 10.7386 6.31841 10.8633 6.43373L11.7986 7.29869C11.9233 7.41402 11.9856 7.54664 11.9856 7.69657C11.9856 7.84649 11.9233 7.97912 11.7986 8.09445L4.44747 14.893L11.7986 21.6915C11.9233 21.8069 11.9856 21.9395 11.9856 22.0894C11.9856 22.2394 11.9233 22.372 11.7986 22.4873L10.8633 23.3523C10.7386 23.4676 10.5952 23.5253 10.4331 23.5253C10.271 23.5253 10.1276 23.4676 10.0029 23.3523L1.28631 15.2909Z" fill="white"/></g><defs><clipPath id="clip0_11_71"><rect width="12" height="31" fill="white" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 12 0.5)"/></clipPath></defs></svg>`,
			`<svg width="12" height="31" viewBox="0 0 12 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7137 16.2091C10.8384 16.3244 10.9007 16.4571 10.9007 16.607C10.9007 16.7569 10.8384 16.8896 10.7137 17.0049L1.99708 25.0663C1.87238 25.1816 1.72898 25.2393 1.56687 25.2393C1.40476 25.2393 1.26135 25.1816 1.13665 25.0663L0.201394 24.2013C0.0766936 24.086 0.0143433 23.9534 0.0143433 23.8034C0.0143433 23.6535 0.0766936 23.5209 0.201394 23.4056L7.5525 16.607L0.201394 9.80845C0.0766936 9.69312 0.0143433 9.5605 0.0143433 9.41057C0.0143433 9.26065 0.0766936 9.12802 0.201394 9.01269L1.13665 8.14774C1.26135 8.03241 1.40476 7.97475 1.56687 7.97475C1.72898 7.97475 1.87238 8.03241 1.99708 8.14774L10.7137 16.2091Z" fill="white"/></svg>`,
		],
	});
});
window.addEventListener("DOMContentLoaded", (event) => {
	const burger = document.querySelector(".header__bottom-burger"),
		menu = document.querySelector(".header__mobile");

	burger.addEventListener("click", () => {
		menu.classList.toggle("active");
		burger.classList.toggle("active");
	});

	const modal = document.querySelector(".modal"),
		trigger = document.querySelectorAll("[data-modal]"),
		close = modal.querySelector(".modal__content-close");

	trigger.forEach((item) => {
		item.addEventListener("click", () => {
			modal.classList.add("active");
		});
	});

	close.addEventListener("click", () => {
		modal.classList.remove("active");
	});
});
